import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import AdContainer from "../../shared-components/AdContainer";
import Badges from "./../../shared-components/Badges";
import ReportStoryCard from "../../shared-components/ReportStoryCard";
import StoryRanksCard from "../../shared-components/StoryRanksCard";
import TableOfContents from "../../shared-components/TableOfContents";
import StoryParts from "../../shared-components/StoryParts";
import Description from "./../../shared-components/Description";
import CollapsingStoryParts from "./../../shared-components/CollapsingStoryParts";
import StoryActions from "./../../shared-components/StoryActions";
import StoryHeader from "./../../shared-components/StoryHeader";
import StoryList from "../../shared-components/StoryList";
import StoryStats from "./../../shared-components/StoryStats";
import AdminPanel from "../../shared-components/AdminPanel";
import TagCarousel from "../tags/TagCarousel";
// import TagGrid from "../tags/TagGrid";
import {
  Button,
  ButtonSize,
  ButtonVariant,
  Icon,
  IconNames,
  TagCloud as TagGrid
} from "@wattpad/web-ui-library";
import StoryError500 from "../../shared-components/StoryError500";
import { useTrans, useFeatureFlags } from "../../hooks";
import {
  getReadTime,
  formatReadTime,
  fromNow,
  sanitizeHTML
} from "../../helpers";
import { STORY_COPYRIGHT_LICENSES } from "../../../components/views/works-item-details/story-details/constants";
var Swatch = require("../../../assets/swatch");
import { useSelector, useDispatch } from "react-redux";
import { getCollectionsForStoryIds, getReadingLists } from "../home/actions";
import AuthForm from "../authentication/AuthForm";
import PremiumPicksCard, {
  PremiumPicksProps
} from "../../shared-components/PremiumPicksCard";
import isPaidStoryHelper from "../../../helpers/is-paid-story";
import classNames from "classnames";

const StoryDetails = ({
  currentUserRoles,
  username, // TODO: evaluate moving away from this and solely using isLoggedIn
  userLang,
  isLoggedIn,
  languages,
  onLanguageChange,
  onCategoryChange,
  categories,
  onFileSelected,
  onCoverRemove,
  isDesktop,
  storyData: {
    id,
    title,
    description,
    createDate,
    numNewParts,
    hasBonusParts,
    numNewBonusParts,
    cover,
    user = {}, // author
    isPaywalled,
    paidModel,
    isPaidPreview,
    premiumPicks,
    length,
    numParts,
    storyTags,
    readCount,
    completed,
    draft,
    deleted,
    rating,
    voteCount,
    firstPartId,
    serverError,
    language,
    storyLanguage,
    lastPublishedPart,
    parts,
    storyReportUrl,
    storyTranslateUrl,
    tagRankings,
    recommendedStories = [],
    sources,
    writerPreviewHasBannedCover,
    copyright,
    firstStoryPart,
    nextUrl,
    storyCategories,
    url
  } = {},
  adData,
  signupModel
}) => {
  const storyTagsWithColor = useMemo(
    () => ({
      ...storyTags,
      tags:
        storyTags &&
        storyTags.tags.map(tag => ({
          ...tag,
          bgColor: `${Swatch["ds-neutral-40"]}`,
          color: `${Swatch["ds-neutral-80"]}`
        }))
    }),
    [storyTags]
  );

  const dispatch = useDispatch();
  const readingLists = useSelector(state => state.homeSections.readingLists);
  const storiesCollections = useSelector(
    state => state.homeSections.storiesCollections
  );
  const featureFlags = useFeatureFlags();

  const [readingPosition, setReadingPosition] = useState({});
  const [isFirstPageLoad, setFirstPageLoad] = useState(true);

  const isPaidStory = isPaidStoryHelper({ paidModel });

  const copyrightLicenses = STORY_COPYRIGHT_LICENSES();
  const copyrightId = copyright ? copyright + "" : "1";
  let license =
    copyrightLicenses &&
    copyrightLicenses.find(license => license.value.toString() === copyrightId);

  useEffect(
    () => {
      if (isFirstPageLoad) {
        // Redirect to canonical url - we're trying to switch to using canonical url everywhere
        if (
          url + window.location.search + window.location.hash !==
          window.location.href
        ) {
          if (typeof wattpad !== "undefined" && title) {
            history.replaceState(
              {},
              title,
              wattpad.utils.formatStoryUrl(url) +
                window.location.search +
                window.location.hash
            );
          }
        }
        // Page view event
        window.te.push("event", "app", "page", null, "view", {
          page: "story_details"
        });
        // Story view event
        window.te.push("event", "story", null, null, "view", {
          storyid: id,
          page: "story_details",
          type: "primary",
          source: "story_details",
          algo_source: sources || ""
        });

        setFirstPageLoad(false);

        if (username) {
          dispatch(getReadingLists());
          dispatch(getCollectionsForStoryIds(id));
        }
      }
    },
    [id, sources, isFirstPageLoad, username, dispatch, url, title]
  );

  useEffect(
    () => {
      if (username) {
        $.get("/apiv2/syncreadingposition", {
          story_id: firstPartId
        }).then(data => {
          setReadingPosition(data || {});
        });
      }
    },
    [username, firstPartId]
  );

  const { trans } = useTrans();

  const size = "16";
  const viewBox = "16";
  const statsItems = [
    {
      icon: "views",
      label: trans("Reads"),
      size: size,
      viewBox: viewBox,
      value: readCount
    },
    {
      icon: "votesBeta",
      label: trans("Votes"),
      size: size,
      viewBox: viewBox,
      value: voteCount
    },
    {
      icon: "partsBeta",
      label: trans("Parts"),
      size: size,
      viewBox: viewBox,
      value: numParts
    }
  ];

  const lastUpdated = lastPublishedPart
    ? fromNow(lastPublishedPart.createDate, {
        fuzzyTime: true
      })
    : null;

  // readTime is a best guess that currently only supports english stories.
  // Gate off displaying this StatsItem for other languages.
  if (language === 1) {
    const readTime = formatReadTime(getReadTime(length));
    statsItems.push({
      icon: "readTime",
      label: trans("Time"),
      size: size,
      viewBox: viewBox,
      hoursVal: readTime.hours,
      minutesVal: readTime.minutes
    });
  }

  const signupSubtitle = trans(
    "Get notified when %s is updated",
    "<strong>" + sanitizeHTML(title) + "</strong>"
  );

  const signupTitle = trans(
    "Sign up to add %s to your library and receive updates",
    "<strong>" + sanitizeHTML(title) + "</strong>"
  );

  return (
    <div className="story-details-page">
      {serverError ? (
        <StoryError500 />
      ) : (
        <>
          <StoryHeader
            title={title}
            cover={cover}
            user={user}
            draft={draft}
            isLoggedInUser={!!username}
            isPaid={isPaywalled}
            isPaidStory={isPaidStory}
            isPaidPreview={isPaidPreview}
            numParts={numParts}
            readCount={readCount}
            storyLength={length}
            voteCount={voteCount}
            firstPartId={firstPartId}
            storyId={id}
            sources={sources}
            statsItems={statsItems}
            readingLists={readingLists}
            storiesCollections={storiesCollections}
            writerPreviewHasBannedCover={writerPreviewHasBannedCover}
          />
          <div className="story-info-container">
            <div className="left-container">
              {deleted && (
                <p className="story-status story-status--important xxs-container-padding">
                  {trans("Deleted")}
                </p>
              )}
              {draft &&
                !deleted && (
                  <p className="story-status xxs-container-padding">
                    {trans("Draft")}
                  </p>
                )}
              <StoryStats
                statsItems={statsItems}
                className={"shown-xxs"}
                showTooltip
              />

              {!isDesktop &&
                premiumPicks && (
                  <PremiumPicksCard premiumPicks={premiumPicks} />
                )}
              {(currentUserRoles.isSysAdmin ||
                currentUserRoles.isAmbassador) && (
                <>
                  <p className="frontend-remix-warning">
                    <Icon name={IconNames.WpWarning} />
                    {trans(
                      "You are currently viewing the old Story Admin view to access the Admin Panel"
                    )}
                  </p>
                  <Button
                    onClick={() => {
                      // Also kick someone out of disable remix mode
                      // for now, until we have a better way to handle this.
                      const COOKIES = [
                        "disable_remix",
                        "force_frontend_story_details"
                      ];

                      COOKIES?.forEach(cookie => {
                        wattpad?.utils?.destroyCookie(cookie, true);

                        // also delete manually created cookies (browser adds www domain, cookies we create mostly don't)
                        wattpad?.utils?.destroyCookie(cookie);
                      });

                      wattpad?.utils?.redirectToServer();
                    }}
                    size={ButtonSize.SMALL}
                    variant={ButtonVariant.SECONDARY}
                    label={trans("Exit Story Details admin mode")}
                  />

                  <AdminPanel
                    currentUserRoles={currentUserRoles}
                    user={user}
                    storyId={id}
                    storyTranslateUrl={storyTranslateUrl}
                    languages={languages}
                    categories={categories}
                    storyCategories={storyCategories}
                    onLanguageChange={onLanguageChange}
                    onCategoryChange={onCategoryChange}
                    storyLanguage={language}
                    onFileSelected={onFileSelected}
                    onCoverRemove={onCoverRemove}
                  />
                </>
              )}
              {!isDesktop && (
                <AdContainer
                  placement="storylanding_bottom_mweb"
                  adData={adData}
                  className="storylanding_bottom_mweb advertisement"
                />
              )}
              <Badges
                isCompleted={completed}
                isMature={rating === 4}
                user={user}
                publishDate={createDate}
                numNewParts={numNewParts}
                numNewBonusParts={numNewBonusParts}
              />
              <Description
                className={"hidden-xxs"}
                description={description}
                license={license}
              />
              {storyTags && (
                <TagGrid
                  tagGridTags={storyTagsWithColor}
                  onTagClicked={name => {
                    app.router.navigate(`/stories/${name}`, {
                      trigger: true
                    });
                  }}
                  className={"hidden-xxs"}
                />
              )}
              {storyTags && (
                <TagCarousel
                  relatedTags={storyTagsWithColor}
                  onTagClicked={name => {
                    app.router.navigate(`/stories/${name}`, {
                      trigger: true
                    });
                  }}
                  className={"shown-xxs"}
                />
              )}
              {hasBonusParts && (
                <div className="extras-text hidden-xxs">
                  {trans("This story contains Extras.")}
                  <a href="https://support.wattpad.com/hc/en-us/articles/10489312731924-Writer-Subscriptions-FAQ">
                    {trans("Learn more.")}
                  </a>
                </div>
              )}
              <TableOfContents
                className={"hidden-xxs"}
                lastUpdated={lastUpdated}
              >
                <StoryParts
                  storyId={id}
                  sources={sources}
                  parts={parts}
                  isPaywalled={isPaywalled}
                  readingPosition={readingPosition}
                />
              </TableOfContents>
            </div>
            <div className="xxs-container-padding right-container">
              {!isLoggedIn && (
                <div
                  className={classNames("signup-modal card hidden-xxs", {
                    "auth-form-width": featureFlags.NEW_ONBOARDING_1,
                    "old-card": !featureFlags.NEW_ONBOARDING_1
                  })}
                >
                  {featureFlags.NEW_ONBOARDING_1 ? (
                    <AuthForm
                      formType="sidebar"
                      title={signupTitle}
                      nextUrl={
                        nextUrl
                          ? nextUrl
                          : (firstStoryPart && firstStoryPart.url) || null
                      }
                      model={signupModel}
                      focus={false}
                    />
                  ) : (
                    <AuthForm
                      formType="sidebar"
                      subtitle={signupSubtitle}
                      nextUrl={(firstStoryPart && firstStoryPart.url) || null}
                      model={signupModel}
                      focus={false}
                    />
                  )}
                </div>
              )}
              {isDesktop && (
                <AdContainer
                  placement="storylanding_top"
                  adData={adData}
                  className="storylanding_top advertisement"
                />
              )}
              <Description
                className={"shown-xxs"}
                isMobile={true}
                description={description}
                license={license}
              />
              {hasBonusParts && (
                <div className="extras-text shown-xxs">
                  {trans("This story contains Extras.")}
                  <a href="https://support.wattpad.com/hc/en-us/articles/10489312731924-Writer-Subscriptions-FAQ">
                    {trans("Learn more.")}
                  </a>
                </div>
              )}
              {isDesktop &&
                premiumPicks && (
                  <PremiumPicksCard premiumPicks={premiumPicks} />
                )}
              <CollapsingStoryParts
                numParts={numParts}
                lastUpdated={lastUpdated}
                className={"shown-xxs"}
                isPaywalled={isPaywalled}
                readingPosition={readingPosition}
                parts={parts}
              />
              <StoryRanksCard storyRankings={tagRankings} storyId={id} />
              <ReportStoryCard
                storyId={id}
                storyTitle={title}
                authorUsername={user.username}
                storyReportUrl={storyReportUrl}
                storyLanguage={storyLanguage}
              />
              {Array.isArray(recommendedStories) &&
                recommendedStories.length > 0 && (
                  <StoryList
                    stories={recommendedStories}
                    userLang={userLang}
                    page={"story_details"}
                  />
                )}
            </div>
          </div>
        </>
      )}
      <div className="action-bar--sticky shown-xxs">
        <StoryActions
          isPaid={isPaywalled}
          isPaidPreview={isPaidPreview}
          isPaidStory={isPaidStory}
          firstPartId={firstPartId}
          storyId={id}
          isLoggedInUser={!!username}
          readingLists={readingLists}
          storiesCollections={storiesCollections}
          sources={sources}
          isMobile={true}
          draft={draft}
        />
      </div>
    </div>
  );
};

StoryDetails.defaultProps = {
  onLanguageChange: () => {},
  onCategoryChange: () => {},
  onFileSelected: () => {},
  onCoverRemove: () => {},
  currentUserRoles: {
    isAdmin: false,
    isAmbassador: false
  }
};

StoryDetails.propTypes = {
  storyData: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    description: PropTypes.string,
    createDate: PropTypes.string,
    user: PropTypes.shape({
      username: PropTypes.string,
      avatar: PropTypes.string,
      totalReads: PropTypes.number,
      age: PropTypes.number,
      country: PropTypes.string
    }),
    cover: PropTypes.string,
    completed: PropTypes.bool,
    draft: PropTypes.bool,
    deleted: PropTypes.bool,
    isPaywalled: PropTypes.bool,
    paidModel: PropTypes.string,
    isPaidPreview: PropTypes.bool,
    premiumPicks: PropTypes.shape(PremiumPicksProps),
    length: PropTypes.number,
    numParts: PropTypes.number,
    storyTags: PropTypes.object,
    readCount: PropTypes.number,
    rating: PropTypes.number,
    voteCount: PropTypes.number,
    firstPartId: PropTypes.number,
    serverError: PropTypes.bool,
    storyReportUrl: PropTypes.string,
    storyTranslateUrl: PropTypes.string,
    tagRankings: PropTypes.array,
    sources: PropTypes.array,
    copyright: PropTypes.number,
    firstStoryPart: PropTypes.shape({
      url: PropTypes.string
    }),
    recommendedStories: PropTypes.array,
    url: PropTypes.string
  }),
  currentUserRoles: PropTypes.shape({
    isSysAdmin: PropTypes.bool,
    isAmbassador: PropTypes.bool
  }),
  username: PropTypes.string,
  userLang: PropTypes.number,
  isLoggedIn: PropTypes.bool,
  signupModel: PropTypes.object,
  languages: PropTypes.array,
  categories: PropTypes.array,
  storyCategories: PropTypes.array,
  onLanguageChange: PropTypes.func,
  onCategoryChange: PropTypes.func,
  onFileSelected: PropTypes.func,
  onCoverRemove: PropTypes.func,
  isDesktop: PropTypes.bool,
  isPremium: PropTypes.bool,
  adData: PropTypes.object
};

export default StoryDetails;
